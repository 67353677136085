import React, { useEffect, useState } from 'react'
import { Platform, Dimensions } from 'react-native'
import {
  Box,
  Text,
  Button,
  Divider,
  TextArea,
  ScrollView,
  useToken
} from 'native-base'
import { Message } from '../types'

type ChatProps = {
  messages: Message[]
  canSendMessage: boolean
  onSendMessage: (message: string) => void
}

const Chat = ({ messages = [], onSendMessage, canSendMessage }: ChatProps) => {
  const [message, setMessage] = useState<string>()
  const [touchBreakpoint] = useToken('breakpoints', ['md'])

  useEffect(() => {
    if (
      Platform.OS === 'web' &&
      Dimensions.get('window').width > touchBreakpoint
    ) {
      //@ts-ignore
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }, [])

  function handleSendMessage() {
    if (message) {
      console.log('send', message)
      onSendMessage(message!)
      setMessage('')
    }
  }

  return (
    <Box flex={1}>
      {Platform.OS === 'web' && (
        <Box display={['none', 'none', 'flex']}>
          <Box m={5} height="280px">
            <ins
              className="adsbygoogle"
              style={{ display: 'block' }}
              data-ad-client="ca-pub-6538434310321045"
              data-ad-slot="5858485316"
              data-ad-format="auto"
              data-adtest="on"
              data-full-width-responsive="true"
            />
          </Box>
          <Divider />
        </Box>
      )}

      {messages.length > 0 && (
        <ScrollView flexShrink="1" flexGrow="1" flexBasis="1" my={3} mx={5}>
          {messages.map((message, index) => (
            <Box key={index} flexDirection="row">
              <Text>
                <Text fontWeight="bold">
                  {message.fromClient ? 'Me' : message.user}
                </Text>
                : {message.message}
              </Text>
            </Box>
          ))}
        </ScrollView>
      )}

      {messages.length == 0 && (
        <Box
          flexShrink="1"
          flexGrow="1"
          flexBasis="1"
          alignItems="center"
          justifyContent="center"
        >
          <Text color="gray.400" fontWeight="bold">
            There is not messages yet
          </Text>
        </Box>
      )}

      <Divider />
      <Box bg="white" p={5} flexDirection="row">
        <TextArea
          mr={5}
          flex={1}
          minH="60px"
          maxH="60px"
          onChange={(e: any) => setMessage(e.currentTarget.value)}
          value={message}
          isDisabled={!canSendMessage}
        />
        <Button isDisabled={!canSendMessage} onPress={handleSendMessage}>
          Send
        </Button>
      </Box>
    </Box>
  )
}

export default Chat
