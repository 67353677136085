import React, { useContext, useState, useRef, useEffect } from 'react'
import { PHONE_STATUSES } from './constants'
import { io, Socket } from 'socket.io-client'
import { Base64 } from 'js-base64'
import * as actions from '../store/actions'
import { useStore } from '../store'
import { Partner } from '../../types'
import Peer, {
  DataConnection,
  getMediaDevices,
  stopTracks
} from '../../utils/peer'
import config from '../../config'

type PhoneContextProps = {
  status: string
  remoteStream?: MediaStream
  partner?: Partner
  connect: () => void
  hangUp: () => void
  nextCall: () => void
  sendMessage: (message: string) => void
}

const PhoneContext = React.createContext<PhoneContextProps>({
  status: PHONE_STATUSES.IDLE,
  connect: () => {},
  hangUp: () => {},
  nextCall: () => {},
  sendMessage: (message: string) => {}
})

type PhoneProviderProps = {
  children: React.ReactNode
}

export const PhoneProvider = ({ children }: PhoneProviderProps) => {
  const peer = useRef<Peer>()
  const socket = useRef<Socket>()
  const conn = useRef<DataConnection>()

  const { state, dispatch } = useStore()
  const refPreferences = useRef(state.preferences)

  const [localStream, setLocalStream] = useState<MediaStream>()
  const refLocalStream = useRef(localStream)

  const [remoteStream, setRemoteStream] = useState<MediaStream>()
  const refRemoteStream = useRef(remoteStream)

  const [peerId, setPeerId] = useState<string>()
  const refPeerId = useRef(peerId)

  const [partner, setPartner] = useState<Partner>()
  const refPartner = useRef(partner)

  const [status, setStatus] = useState<keyof typeof PHONE_STATUSES>(
    PHONE_STATUSES.IDLE
  )

  useEffect(() => {
    refPreferences.current = state.preferences
  }, [state.preferences])

  function connect() {
    setStatus(PHONE_STATUSES.CONNECTING)
    peer.current = new Peer()
    peer.current.on('open', (id) => {
      setPeerId(() => {
        refPeerId.current = id
        return id
      })
      getMediaDevices()
        .then((stream: any) => {
          setLocalStream(() => {
            refLocalStream.current = stream
            return stream
          })
          connectToCallServer()
          peer.current?.on('call', (call) => {
            peer.current?.connect(call.peer)
            call.answer(refLocalStream.current!)
            call.on('error', (error) => {
              console.log('error: receiving call', error.type)
            })
            call.on('stream', onReceiveRemoteStream)
          })
        })
        .catch((err) => {
          console.log(err)
        })
    })

    peer.current.on('disconnect', hangUp)
    peer.current.on('error', hangUp)
  }

  function connectToCallServer() {
    if (socket.current && socket.current.connected) return
    socket.current = io(config.SERVER_URL, { transports: ['websocket'] })
    socket.current.on('connect', onConnectToCallServer)

    socket.current.on('call', (data) => {
      setPartner(() => {
        refPartner.current = data
        return data
      })

      if (data.receiver === false) {
        const call = peer.current?.call(data.peerId, refLocalStream.current!)
        call?.on('error', (error) => {
          console.log('error: calling', error.type)
        })
        call?.on('stream', onReceiveRemoteStream)
      }
    })

    socket.current.on('hangUp', () => {
      nextCall()
    })
  }

  function onReceiveRemoteStream(stream: MediaStream) {
    setRemoteStream(() => {
      refRemoteStream.current = stream
      return stream
    })
    peer.current?.on('connection', onConnectToMessageLayer)
    conn.current = peer.current?.connect(refPartner.current?.peerId!)
    conn.current?.on('open', onConnectToMessageLayer)
    setStatus(PHONE_STATUSES.CALL_ESTABLISHED)
  }

  function onConnectToCallServer() {
    setStatus(PHONE_STATUSES.CONNECTED)
    socket.current?.emit('join', {
      user: {
        peerId: refPeerId.current,
        ...refPreferences.current
      }
    })
  }

  function onConnectToMessageLayer(connection?: DataConnection) {
    console.log('connected to message layer')
    conn.current = connection ? connection : conn.current
    conn.current?.on('data', (message) => {
      actions
        .receiveMessage({
          user: refPartner.current?.nickname!,
          timestamp: Date.now(),
          message: Base64.decode(message)
        })
        .then(dispatch)
    })
  }

  function hangUp() {
    socket.current?.offAny()
    socket.current?.disconnect()
    peer.current?.destroy()
    stopTracks(localStream)
    setStatus(PHONE_STATUSES.IDLE)
  }

  function nextCall() {
    socket.current?.offAny()
    socket.current?.disconnect()
    peer.current?.destroy()
    connect()
  }

  function sendMessage(message: string) {
    conn.current?.send(Base64.encode(message))
    actions
      .sendMessage({
        user: refPreferences.current.nickname!,
        timestamp: Date.now(),
        fromClient: true,
        message
      })
      .then(dispatch)
  }

  return (
    <PhoneContext.Provider
      value={{
        status,
        partner,
        connect,
        nextCall,
        hangUp,
        sendMessage,
        remoteStream
      }}
    >
      {children}
    </PhoneContext.Provider>
  )
}

export function usePhone() {
  return useContext(PhoneContext)
}
