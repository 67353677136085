import React, { useRef, useEffect } from 'react'
import Icon from './Icon'
import Timer from './Timer'
import {
  Box,
  Heading,
  FormControl,
  Select,
  Stack,
  IconButton,
  Text
} from 'native-base'
import { AudioElement } from '../utils/peer'
import { useForm, Controller } from 'react-hook-form'
import { Partner, State } from '../types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Platform } from 'react-native'

type FormData = {
  level: string
  language: string
  partnerGender: string
}

type PhoneButtonProps = {
  onPress: () => void
  primary?: boolean
  colorScheme?: string
  icon: IconProp
  isDisabled?: boolean
  children: React.ReactNode
}

const PhoneButton = ({
  onPress,
  primary,
  colorScheme = 'gray',
  icon,
  isDisabled = false,
  children
}: PhoneButtonProps) => {
  return (
    <Box justifyContent="center">
      <Box
        flex={primary ? null : 1}
        justifyContent="center"
        alignItems="center"
      >
        <IconButton
          isDisabled={isDisabled}
          opacity={isDisabled ? 0.3 : 1}
          variant="solid"
          borderRadius="999"
          padding={primary ? [6, 7] : [4, 5]}
          onPress={onPress}
          colorScheme={colorScheme}
          _icon={{
            as: <Icon icon={icon} color="white" size={primary ? 42 : 28} />,
            pointerEvents: 'none'
          }}
        />
      </Box>
      <Text mt={4} textAlign="center">
        {children}
      </Text>
    </Box>
  )
}

type PhoneProps = {
  status: string
  partner?: Partner
  preferences: State['preferences']
  remoteStream?: MediaStream
  onCall: (preferences: State['preferences']) => void
  onBack: () => void
  onHangUp: () => void
  onCallNext: () => void
}

const Phone = ({
  status,
  partner,
  preferences,
  remoteStream,
  onCall,
  onBack,
  onHangUp,
  onCallNext
}: PhoneProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      language: preferences.language,
      level: preferences.level,
      partnerGender: preferences.partnerGender
    }
  })

  const audioRef = useRef<HTMLAudioElement>(null)
  const isIdle = status === 'IDLE'
  const isConnecting = status === 'CONNECTING'
  const isConnected = status === 'CONNECTED'
  const isCallEstablished = status === 'CALL_ESTABLISHED'

  useEffect(() => {
    if (
      isConnected &&
      remoteStream &&
      audioRef.current &&
      Platform.OS === 'web'
    ) {
      // @ts-ignore
      audioRef.current.srcObject = remoteStream
      audioRef.current.addEventListener('loadedmetadata', audioRef.current.play)
    }
  }, [isConnected, remoteStream])

  return (
    <Box flex={1}>
      {isIdle && (
        <>
          <Heading size="md" mb={5} textAlign="center">
            Find a Partner
          </Heading>

          <Stack space="4">
            <Controller
              name="language"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl isRequired isInvalid={!!errors.language}>
                  <Select
                    bg="white"
                    selectedValue={value}
                    onValueChange={onChange}
                    accessibilityLabel="Language"
                    placeholder="Language"
                  >
                    <Select.Item label="English" value="english" />
                    <Select.Item label="Spanish" value="spanish" />
                  </Select>
                  {errors.language && (
                    <FormControl.ErrorMessage>
                      Language is required
                    </FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="level"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl isRequired isInvalid={!!errors.level}>
                  <Select
                    bg="white"
                    selectedValue={value}
                    onValueChange={onChange}
                    accessibilityLabel="Level"
                    placeholder="Level"
                  >
                    <Select.Item label="Beginner Level" value="beginner" />
                    <Select.Item
                      label="Intermediate Level"
                      value="intermediate"
                    />
                    <Select.Item label="Advanced Level" value="advanced" />
                    <Select.Item label="Proficent Level" value="proficent" />
                  </Select>
                  {errors.level && (
                    <FormControl.ErrorMessage>
                      Language Level is required
                    </FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="partnerGender"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl isInvalid={!!errors.partnerGender}>
                  <Select
                    bg="white"
                    selectedValue={value}
                    onValueChange={onChange}
                    accessibilityLabel="Partner Gender"
                    placeholder="Partner Gender"
                  >
                    <Select.Item label="Any Partner Gender" value="" />
                    <Select.Item label="Female" value="female" />
                    <Select.Item label="Male" value="male" />
                  </Select>
                  {errors.partnerGender && (
                    <FormControl.ErrorMessage>
                      Partner Gender is required
                    </FormControl.ErrorMessage>
                  )}
                </FormControl>
              )}
            />
          </Stack>
        </>
      )}

      {isConnecting && (
        <Heading size="md" mb={5} textAlign="center">
          Connecting...
        </Heading>
      )}

      {isConnected && (
        <Heading size="md" mb={5} textAlign="center">
          Calling...
        </Heading>
      )}

      {isCallEstablished && (
        <>
          <Box
            mb={5}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Heading size="md" textAlign="center">
              {partner?.nickname}
            </Heading>
            <Box py={1} px={2} bg="black" borderRadius="full" ml={2}>
              <Icon icon={['far', 'venus']} color="white" size={14} />
            </Box>
          </Box>
          <Timer />
        </>
      )}

      <Box
        mt="auto"
        flexDirection="row"
        alignItems="stretch"
        justifyContent={['space-around', 'space-between']}
      >
        <PhoneButton onPress={onBack} icon={['far', 'times']}>
          Back
        </PhoneButton>
        <PhoneButton
          icon="phone"
          primary={true}
          onPress={isIdle ? handleSubmit(onCall) : onHangUp}
          colorScheme={
            isConnecting || isConnected || isCallEstablished ? 'red' : 'primary'
          }
        >
          {isIdle ? 'Press to call' : 'Hang up'}
        </PhoneButton>
        <PhoneButton
          icon={['far', 'random']}
          onPress={onCallNext}
          isDisabled={!isCallEstablished}
        >
          Next
        </PhoneButton>
      </Box>

      {remoteStream && Platform.OS === 'web' && <AudioElement ref={audioRef} />}
      {remoteStream && Platform.OS !== 'web' && (
        //@ts-ignore
        <AudioElement streamURL={remoteStream.toURL()} />
      )}
    </Box>
  )
}

export default Phone
