import React from 'react'
import { ImageURISource, Platform } from 'react-native'
import { Box, IBoxProps, Image } from 'native-base'

type ContentParagraphProps = {
  image: { alt: string; source: ImageURISource }
  children: React.ReactNode
  align: 'left' | 'right'
} & IBoxProps
export default function ContentParagraph({
  image,
  children,
  align,
  ...rest
}: ContentParagraphProps) {
  const imageProps = {
    ...image,
    alt: image.alt,
    width: ['280px', '320px', '400px'],
    height: ['210px', '240px', '300px']
  }
  return (
    <Box
      mt={6}
      py={8}
      px={4}
      alignItems="center"
      borderRadius="lg"
      flexDirection={['column', 'row']}
      justifyContent="space-around"
      {...rest}
    >
      <Box
        display={align === 'right' ? ['flex', 'none'] : 'flex'}
        width={['100%', '35%']}
        alignItems={['center', 'flex-end']}
      >
        <Image {...imageProps} />
      </Box>

      <Box width={['100%', '45%', '35%']} alignItems={['center', 'unset']}>
        {children}
      </Box>
      {align === 'right' && (
        <Box
          display={['none', 'flex']}
          width={['100%', '35%']}
          alignItems={['center', 'flex-end']}
        >
          <Image {...imageProps} />
        </Box>
      )}
    </Box>
  )
}
