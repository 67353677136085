import * as types from './constants'
import { State } from '../../types'

export default function reducer(state: State, action: any): State {
  switch (action.type) {
    case types.INITIALIZE: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload.preferences
        },
        messages: action.payload.messages,
        unreadMessages: action.payload.unreadMessages,
        initialized: true
      }
    }
    case types.SAVE_PREFERENCES: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload.preferences
        }
      }
    }
    case types.RECEIVE_MESSAGE: {
      return {
        ...state,
        messages: action.payload.messages,
        unreadMessages: action.payload.unreadMessages
      }
    }
    case types.SEND_MESSAGE: {
      return {
        ...state,
        messages: action.payload.messages
      }
    }
    case types.READ_MESSAGES: {
      return {
        ...state,
        unreadMessages: false
      }
    }
    default:
      return state
  }
}
