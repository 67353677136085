import AsyncStorage from '@react-native-async-storage/async-storage'

async function set(key: string, value: any) {
  return await AsyncStorage.setItem(key, JSON.stringify(value))
}

async function get(key: string, _default: any) {
  //@ts-ignore
  const value = JSON.parse(await AsyncStorage.getItem(key))
  return value || _default
}

const store = { set, get }

export default store
