import React, { useEffect, useState } from 'react'
import { Text, Box } from 'native-base'

const Timer = () => {
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(0)

  useEffect(() => {
    let interval = setInterval(() => {
      setElapsedSeconds((value: number) => value + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Box>
      <Text textAlign="center" fontSize="48px">
        {new Date(elapsedSeconds * 1000).toISOString().substr(11, 8)}
      </Text>
    </Box>
  )
}
export default Timer
