import React from 'react';
import { Box, IBoxProps } from 'native-base';

type ContainerProps = IBoxProps & {
  children: React.ReactNode;
};

const Container = ({ children, ...rest }: ContainerProps) => {
  return (
    <Box px={[8, 12]} maxW="1280px" mx="auto" w="100%" {...rest}>
      {children}
    </Box>
  );
};

export default Container;
