import React from 'react'
import { useToken } from 'native-base'

import { faPhone as fasPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faTimes as fasTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faArrowLeft as fasArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faPlusCircle as fasPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faCog as fasCog } from '@fortawesome/free-solid-svg-icons/faCog'

import { faTimes as farTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faRandom as farRandom } from '@fortawesome/pro-regular-svg-icons/faRandom'
import { faCommentAltDots as farCommentAltDots } from '@fortawesome/pro-regular-svg-icons/faCommentAltDots'
import { faAngleLeft as farAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'

import { faVenus as farVenus } from '@fortawesome/pro-regular-svg-icons/faVenus'
import { faMars as farMars } from '@fortawesome/pro-regular-svg-icons/faMars'

import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes'

import { faGoogle as fabGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  Props as FontAwesomeIconProps
} from '@fortawesome/react-native-fontawesome'

library.add(
  // solid
  fasTimes,
  fasArrowLeft,
  fasPhone,
  fasPlusCircle,
  fasCog,

  // regular
  farTimes,
  farRandom,
  farCommentAltDots,
  farAngleLeft,
  farVenus,
  farMars,
  
  // light
  falTimes,

  // brands
  fabGoogle
)

type IconProps = FontAwesomeIconProps & {
  color?: string | number
}

const Icon = ({ color = 'black', ...rest }: IconProps) => {
  const [colorCode] = useToken('colors', [color])
  return <FontAwesomeIcon size={16} color={colorCode} {...rest} />
}

export default Icon
