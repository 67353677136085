import React from 'react'
import { Platform } from 'react-native'
import { Heading as NativeBaseHeading, ITextProps } from 'native-base'

type Heading = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: React.ReactNode
} & ITextProps

export default function Heading({ as = 'h1', children, ...rest }: Heading) {
  return (
    <NativeBaseHeading {...rest}>
      {Platform.OS === 'web' ? React.createElement(as, {}, children) : children}
    </NativeBaseHeading>
  )
}
