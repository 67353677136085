import { extendTheme } from 'native-base'

const extendedTheme = extendTheme({})

const theme = extendTheme({
  components: {
    Button: {
      defaultProps: {
        px: 6,
        py: 3,
        fontSize: '16px',
        borderRadius: 'md'
      }
    },
    Input: {
      defaultProps: {
        px: 4,
        py: 3,
        fontSize: '16px',
        borderRadius: 'md'
      }
    },
    Select: {
      defaultProps: {
        px: 4,
        py: 3,
        fontSize: '16px',
        borderRadius: 'md'
      }
    },
    Text: {
      defaultProps: {
        color: 'text.normal'
      }
    },
    Heading: {
      defaultProps: {
        lineHeight: 'md'
      }
    }
  },
  colors: {
    primary: extendedTheme.colors.indigo,
    text: {
      normal: extendedTheme.colors.dark['50']
    }
  },
  breakpoints: {
    '2xl': 1920,
    base: 0,
    sm: 768, // tablet
    md: 992, // laptop
    lg: 1280, // desktop
    xl: 1536
  }
})

export default theme
