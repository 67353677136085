import React, { useEffect, useReducer, useContext } from 'react'
import store from '../../utils/store'
import * as actions from './actions'
import reducer from './reducer'
import { State } from '../../types'

const initialState: State = {
  preferences: {
    nickname: undefined,
    gender: undefined,
    level: 'beginner',
    partnerGender: ''
  },
  messages: [],
  unreadMessages: false,
  initialized: false
}

export const StoreContext = React.createContext({})
export const DispatchContext = React.createContext<any>(null)

type StoreProviderProps = {
  children: React.ReactNode
}

export function StoreProvider({ children }: StoreProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    async function setInitialData() {
      const preferences = await store.get('preferences', {})
      const messages = await store.get('messages', [])
      const unreadMessages = await store.get('unreadMessages', false)
      dispatch(actions.initialize({ preferences, messages, unreadMessages }))
    }
    setInitialData()
  }, [])
  return (
    <StoreContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {state.initialized ? children : null}
      </DispatchContext.Provider>
    </StoreContext.Provider>
  )
}

export function useStore() {
  return {
    state: useContext(StoreContext) as State,
    dispatch: useContext(DispatchContext)
  }
}
