import React from 'react';
import { View, StyleSheet, ActivityIndicator, Modal } from 'react-native';

type LoaderProps = {
  isOpen: boolean;
};

const Loader = ({ isOpen }: LoaderProps) => {
  return (
    <Modal
      transparent={true}
      animationType="fade"
      visible={isOpen}
      style={{ zIndex: 1100 }}
      onRequestClose={() => {}}>
      <View style={styles.background}>
        <ActivityIndicator animating={true} size="large" color="#ffffff" />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: '#rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
});

export default Loader;
