import { NativeBaseProvider } from 'native-base'
import Home from './src/screens/Home'
import theme from './src/theme'
import { PhoneProvider } from './src/context/phone'
import { StoreProvider } from './src/context/store/index'

export default function App() {
  return (
    <NativeBaseProvider theme={theme}>
      <StoreProvider>
        <PhoneProvider>
          <Home />
        </PhoneProvider>
      </StoreProvider>
    </NativeBaseProvider>
  )
}
