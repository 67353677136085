import React from 'react'
import Peer, { DataConnection } from 'peerjs'

function getMediaDevices() {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(resolve)
      .catch(reject)
  })
}

function stopTracks(stream: MediaStream) {
  stream?.getTracks().forEach((element) => {
    element.stop()
  })
}

const AudioElement = React.forwardRef((props: any, ref) => {
  return <audio ref={ref} {...props} />
})

export default Peer
export { DataConnection, getMediaDevices, stopTracks, AudioElement }
