import React, { useState } from 'react'

export default function useAds() {
  const [showingAd, setShowingAd] = useState<boolean>(false)

  async function displayInterstitial(afterAdDisplayed: any) {
    afterAdDisplayed()
  }
  return { showingAd, displayInterstitial }
}
