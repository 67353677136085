import React from 'react'
import { Platform } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  Text,
  Box,
  Input,
  Stack,
  Select,
  Image,
  FormControl,
  HStack,
  ScrollView
} from 'native-base'
import Heading from '../components/Heading'
import * as actions from '../context/store/actions'
import { useStore } from '../context/store'
import { usePhone } from '../context/phone'
import Header from '../components/Header'
import Container from '../components/Container'
import Phone from '../components/Phone'
import Chat from '../components/Chat'
import Loader from '../components/Loader'
import ContentParagraph from '../components/ContentParagraph'
import { VIEWS } from '../app.constants'
import { State } from '../types'
import useAds from '../hooks/useAds'
import store from '../utils/store'
import { PHONE_STATUSES } from '../context/phone/constants'

type FormData = {
  nickname: string
  gender: string
}

export default function Home() {
  const phone = usePhone()
  const { state, dispatch } = useStore()
  const { showingAd, displayInterstitial } = useAds()
  const [view, setView] = React.useState<string>(VIEWS.WELCOME)
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      nickname: state.preferences.nickname,
      gender: state.preferences.gender
    }
  })

  function goToCallScreen(data: FormData) {
    setView(VIEWS.CALL)
    actions.savePreferences(data as State['preferences']).then(dispatch)
  }

  function goToHomeScreen() {
    setView(VIEWS.WELCOME)
    phone.hangUp()
  }

  async function afterDisplayAd(callback: any) {
    const calls = (await store.get('calls', 0)) + 1
    await store.set('calls', calls === 4 ? 0 : calls)

    if (calls === 2) {
      displayInterstitial(callback)
    } else {
      callback()
    }
  }

  function onCall(preferences: State['preferences']) {
    actions.savePreferences(preferences).then(dispatch)
    afterDisplayAd(phone.connect)
  }

  function onHangUp() {
    phone.hangUp()
  }

  function onNextCall() {
    afterDisplayAd(phone.nextCall)
  }

  return state.initialized ? (
    <Box flex={1}>
      <Box safeArea flex={1}>
        <Header />
        {view === VIEWS.WELCOME && (
          <Container mb={16}>
            <ScrollView>
              <Box
                flexDirection="row"
                alignItems="center"
                height={['auto', 'auto', '380px']}
              >
                <Box flex="1">
                  <Heading
                    mb="12"
                    minW="320px"
                    maxW={['420px', '480px']}
                    mt={['10', '12', 0]}
                  >
                    Practice languages talking with people around the world
                  </Heading>
                  <Box>
                    <Text
                      mb={5}
                      textAlign="left"
                      fontWeight="bold"
                      fontSize={['lg', 'xl']}
                    >
                      About you
                    </Text>
                    <Stack
                      direction={['column', 'column', 'row']}
                      space="4"
                      alignItems="flex-start"
                    >
                      <Controller
                        name="nickname"
                        control={control}
                        rules={{ required: true }}
                        defaultValue={state.preferences.nickname}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <FormControl
                            isRequired
                            isInvalid={!!errors.nickname}
                            maxW={[null, null, '180px', '250px']}
                          >
                            <Input
                              placeholder="Nickname"
                              width="100%"
                              onBlur={onBlur}
                              onChangeText={onChange}
                              value={value}
                            />
                            {errors.nickname && (
                              <FormControl.ErrorMessage>
                                Nickname is requried
                              </FormControl.ErrorMessage>
                            )}
                          </FormControl>
                        )}
                      />
                      <Controller
                        name="gender"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            isRequired
                            isInvalid={!!errors.gender}
                            maxW={[null, null, '140px', '200px']}
                          >
                            <Select
                              selectedValue={value}
                              accessibilityLabel="Gender"
                              placeholder="Gender"
                              onValueChange={onChange}
                            >
                              <Select.Item label="Female" value="female" />
                              <Select.Item label="Male" value="male" />
                            </Select>
                            {errors.gender && (
                              <FormControl.ErrorMessage>
                                Gender is required
                              </FormControl.ErrorMessage>
                            )}
                          </FormControl>
                        )}
                      />
                      <Button
                        onPress={handleSubmit(goToCallScreen)}
                        size="lg"
                        width={['100%', '100%', 'auto']}
                      >
                        Let's Practice
                      </Button>
                    </Stack>
                  </Box>
                </Box>

                {Platform.OS === 'web' && (
                  <Box display={['none', 'none', 'flex']}>
                    <Image
                      alt="Poeple having a voice call"
                      source={require('./../../assets/images/hero.png')}
                      width={['320px', '320px', '400px', '500px']}
                      height={['240px', '240px', '300px', '375px']}
                    />
                  </Box>
                )}
              </Box>

              {Platform.OS === 'web' && (
                <ContentParagraph
                  align="left"
                  background={Platform.OS === 'web' ? 'indigo.50' : 'white'}
                  image={{
                    alt: 'A person using the cell phone while skating',
                    source: require('./../../assets/images/mobile.png')
                  }}
                >
                  <>
                    <Heading
                      as="h2"
                      mb={[6, 10, 12]}
                      fontSize={['2xl', '2xl', '2xl', '3xl']}
                      textAlign={['center', 'unset']}
                    >
                      Talklearn is also available on mobile, practice anytime,
                      anywhere
                    </Heading>
                    <HStack space={4}>
                      <Image
                        alt="App Store logo"
                        source={require('./../../assets/images/app-store.png')}
                        width="128px"
                        height="40px"
                      />
                      <Image
                        alt="Play Store logo"
                        source={require('./../../assets/images/play-store.png')}
                        width="128px"
                        height="40px"
                      />
                    </HStack>
                  </>
                </ContentParagraph>
              )}

              <ContentParagraph
                align="right"
                background={Platform.OS === 'web' ? 'white' : 'indigo.50'}
                image={{
                  alt: 'A woman working on her laptop at home',
                  source: require('./../../assets/images/how-it-works.png')
                }}
              >
                <>
                  <Heading
                    as="h2"
                    mb={[6, 10]}
                    fontSize={['2xl', '2xl', '2xl', '3xl']}
                  >
                    How does it works?
                  </Heading>
                  <Text fontSize="lg" textAlign={['center', 'unset']}>
                    Talklearn is a voice calling application that allows you to
                    practice languages with people of a similar level to yours.
                    It's completely{' '}
                    <Text color="indigo.500" fontWeight="bold">
                      FREE
                    </Text>
                    , you can talk for as many minutes as you want!
                  </Text>
                </>
              </ContentParagraph>

              <ContentParagraph
                align="left"
                background={Platform.OS === 'web' ? 'indigo.50' : 'white'}
                image={{
                  alt: '2 People sharing interests and experiences',
                  source: require('./../../assets/images/community.png')
                }}
              >
                <>
                  <Heading
                    as="h2"
                    mb={[6, 10]}
                    fontSize={['2xl', '2xl', '2xl', '3xl']}
                  >
                    Enjoy the community!
                  </Heading>
                  <Text fontSize="lg" textAlign={['center', 'unset']}>
                    Find a language partner, meet new friends and don’t forget
                    to be respectful. Let’s keep the community safe and vibrant!
                    If necessary, you can skip or hang up the call at any time.
                  </Text>
                </>
              </ContentParagraph>
            </ScrollView>
          </Container>
        )}

        {view === VIEWS.CALL && (
          <Box flexDirection="row" flex={1}>
            <Box flex="1" justifyContent="center">
              <Container
                py={5}
                maxW="420px"
                height={['560px', '560px', '460px']}
              >
                <Phone
                  preferences={state.preferences}
                  status={phone.status}
                  remoteStream={phone.remoteStream}
                  partner={phone.partner}
                  onBack={goToHomeScreen}
                  onCall={onCall}
                  onHangUp={onHangUp}
                  onCallNext={onNextCall}
                />
              </Container>
            </Box>
            {Platform.OS === 'web' && (
              <Box
                width="400px"
                display={['none', 'none', 'flex']}
                borderLeftWidth="1px"
                borderLeftColor="gray.200"
              >
                <Chat
                  messages={state.messages}
                  onSendMessage={phone.sendMessage}
                  canSendMessage={
                    phone.status === PHONE_STATUSES.CALL_ESTABLISHED
                  }
                />
              </Box>
            )}
          </Box>
        )}
        <Loader isOpen={showingAd} />
      </Box>
    </Box>
  ) : null
}
