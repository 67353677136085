import React, { useEffect } from 'react'
import Modal from 'react-native-modal'
import { Box, Heading, HStack, Pressable } from 'native-base'
import Container from './Container'
import Icon from './Icon'
import Chat from './Chat'
import * as actions from '../context/store/actions'
import { usePhone } from '../context/phone'
import { PHONE_STATUSES } from '../context/phone/constants'
import { useStore } from '../context/store'

const Header = () => {
  const phone = usePhone()
  const { state, dispatch } = useStore()
  const [isChatOpen, setIsChatOpen] = React.useState(false)

  useEffect(() => {
    if (isChatOpen === true) {
      actions.readMessages().then(dispatch)
    }
  }, [state.messages, isChatOpen])

  return (
    <>
      <Box
        shadow={[2, 2]}
        height="64px"
        bg="white"
        zIndex={9}
        justifyContent="center"
      >
        <Container flexDirection="row" alignItems="center" maxW={null}>
          <Heading size="md">Talklearn</Heading>
          <HStack display={['flex', 'flex', 'none']} ml="auto">
            <Pressable onPress={() => setIsChatOpen(true)}>
              <Icon icon={['far', 'comment-alt-dots']} size={20} />
              {state.unreadMessages && (
                <Box
                  width="12px"
                  height="12px"
                  borderRadius="999px"
                  background="primary.500"
                  position="absolute"
                  right="-4px"
                  top="-4px"
                />
              )}
            </Pressable>
          </HStack>
        </Container>
      </Box>

      <Modal
        isVisible={isChatOpen}
        onBackdropPress={() => setIsChatOpen(false)}
        style={{ justifyContent: 'flex-end', margin: 0 }}
      >
        <Box bg="white" flex={1} safeArea>
          <HStack m={5} space={4} alignItems="center">
            <Pressable onPress={() => setIsChatOpen(false)}>
              <Box pointerEvents="none">
                <Icon icon={['far', 'angle-left']} size={26} />
              </Box>
            </Pressable>
            <Heading>Chat</Heading>
          </HStack>
          <Chat
            messages={state.messages}
            onSendMessage={phone.sendMessage}
            canSendMessage={phone.status === PHONE_STATUSES.CALL_ESTABLISHED}
          />
        </Box>
      </Modal>
    </>
  )
}

export default Header
